import CardContainer from "../components/CardContainer";
import styles from "./Section2.module.css";


const Section2 = () => {

  return (
    <div className={styles.section2}>
      <b className={styles.b}>쉽게 따라해 보세요</b>
      <CardContainer
        stepNumber="01. "
        rfpAutoFillText="RFP  자동작성"
        rfpStepTitle="Step by Step으로 RFP(대출제안요청서)를 작성"
        rfpImageUrl="/image-4@2x.png"
        rfpDescription="가이드에 따라 손쉽게 RFP를 작성하면 10분안에 가능해요. 금융회사 대출검토를 위한 필요사항들이 사전 정의되어 있어요"
      /> 
      
      <img className={styles.vectorIcon} alt="" src="/vector.svg" />
      <CardContainer
        stepNumber="02. "
        rfpAutoFillText="금리와 한도 등을 비교"
        rfpStepTitle="복수의 금융회사 전문가들의 제안서를 비교  검토"
        rfpImageUrl="/image-5@2x.png"
        rfpDescription="회사의 기본 정보와 RFP내용을 바탕으로 다수의 금융회사 기업전문인력이 제안서를 발송하면, 비교와 검토를 진행해요"
        spanWidth="246px"
      />
      <img className={styles.vectorIcon} alt="" src="/vector.svg" />
      <CardContainer
        stepNumber="03. "
        rfpAutoFillText="최적의 전문가와 매칭"
        rfpStepTitle="최적의  조건 등을 제시한 담당자를 선택하여 매칭"
        rfpImageUrl="/image-6@2x.png"
        rfpDescription="매칭이 완료되면 상호간의 연락처가 공유돼요. 금융회사의 승인절차 등을 거쳐 대출절차를 완료하세요.  "
        spanWidth="246px"
      />
      <img className={styles.image7Icon} alt="" src="/image-7@2x.png" />
    
    </div>
  );
};

export default Section2;
