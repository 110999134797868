import { useCallback } from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  const onHttpsloangokrClick = useCallback(() => {
    window.location.href = "https://loango.kr";
  }, []);

  return (
    <div className={styles.footer}>
      <img className={styles.icon} alt="" src="/whitelogo.png" />
      <div className={styles.simpleFundingWith}>
        Simple Funding With One Click
      </div>
      <div className={styles.footerChild} />
      <div className={styles.copyrightDemandlink}>
        COPYRIGHT ⓒ DemandLink INC. ALL RIGHTS RESERVED.
      </div>
      <a
        className={styles.httpsloangokr}
        href="https://loango.kr"
        onClick={onHttpsloangokrClick}
      >{`Https://loango.kr `}</a>
    </div>
  );
};

export default Footer;
