import styles from "./Section3.module.css";

const Section3 = () => {
  return (
    <div className={styles.section3}>
      <b className={styles.b}>관계금융과 디지털의 융합</b>
      <div className={styles.iconBuildingParent}>
        <img className={styles.iconBuilding} alt="" src="/icon-building.svg" />
        <div className={styles.div}>은행부터 저축은행, 캐피탈까지</div>
      </div>
      <div className={styles.iconBuildingParent}>
        <img className={styles.iconBuilding} alt="" src="/icon-smile.svg" />
        <div className={styles.div}>ONLINE ONLY, EASY TO USE</div>
      </div>
      <div className={styles.iconCertifyParent}>
        <img className={styles.iconBuilding} alt="" src="/icon-certify.svg" />
        <div className={styles.div}>
          명함 및 본인인증으로 금융회사 직원 인증
        </div>
      </div>
      <div className={styles.iconCertifyParent}>
        <img className={styles.iconBuilding} alt="" src="/icon-good.svg" />
        <div className={styles.div}>RFP 자동 큐레이션으로 보다 간편하게</div>
      </div>
    </div>
  );
};

export default Section3;
