import './App.css';
import { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import {isMobile} from "react-device-detect";
import HeaderSection from './pages/Header';
import Section1 from './pages/Section1';
import Section2 from './pages/Section2';
import Section3 from './pages/Section3';
import Section4 from './pages/Section4';
import Section5 from './pages/Section5';
import Section6 from './pages/Section6';
import FooterSection from './pages/Footer';

function RedirectComponent() {
  useEffect(() => {
    window.location.href = 'https://loango.kr'; 
  }, []);

  return null;
}

function App() {

  useEffect(() => {
    AOS.init({
      offset: 300, // Offset (in pixels) from the top of the element when animation starts
      duration: 1200, // Animation duration in milliseconds
      easing: 'ease-in-out', // Animation timing function
      delay: 0, // Delay in milliseconds before the animation starts
      once: true,
    });
  }, []);

  if(!isMobile){
  RedirectComponent();
  } else { 
   
  return (
  <div className='App'>
  <HeaderSection></HeaderSection>

  <Section1></Section1> 
  <div data-aos="fade-up">

  <Section2></Section2> </div>
  <div data-aos="fade-up">
  <Section3></Section3></div>

  <div data-aos="fade-up">
  <Section4></Section4> </div>

  <div data-aos="fade-up">
  <Section5></Section5> </div>

  <div data-aos="fade-up">
  <Section6></Section6> </div>

  <FooterSection></FooterSection>
  </div>
);
}}

export default App;
