import styles from "./FaqContainer.module.css";
import { useState } from 'react';
import Collapse from '@mui/material/Collapse';

const FaqContainer = (props) => {

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [text, setText] = useState(props.before);
  const [textColor, setTextColor] = useState('black');

  const handleClick= () => {
    setIsCollapsed(!isCollapsed);
    if(isCollapsed===false){
    setText(props.after);
    setTextColor("darkblue");} else{
      setText(props.before);
      setTextColor("black");
    }
  };

  return (

      <div className={styles.qa}>
        <div className={styles.div} onClick={handleClick} style={{ color: textColor }}>{text}</div>
        <img className={styles.qaChild} alt="" src="/line-1.svg" />
        <Collapse in = {isCollapsed}>
        <div className={styles.rfp}>
         {props.detail}
        </div>
        </Collapse>
      </div>
    
  );
};

export default FaqContainer;

