import styles from "./Section6.module.css";
import Faq from '../components/FaqContainer';
import { list } from "./FaqList";

const Section6 = () => {

  return (
    <div className={styles.section6}>
      <b className={styles.b}>자주 묻는 질문들</b>
      <div className={styles.container}>
            {list.map((item) => {
        return <Faq key={item.before} {...item} /> 
      })}    
      </div>
    </div>
  );
};

export default Section6;
