import styles from "./Section1.module.css";

const Section1 = () => {
  return (
    <div className={styles.section1}>
      <div className={styles.div}>
        <img
          className={styles.announcementIcon}
          alt=""
          src="/announcement.svg"
        />
       
        <div className={styles.pc}>현재 PC에서만 이용이 가능해요</div>
      </div>
      <div className={styles.mainment}>
        <div className={styles.div1}>우리회사 대출,</div>
        <b className={styles.b}>어떻게 알아 보고 계신가요?</b>
        <div className={styles.ment}>
          <img className={styles.image1Icon} alt="" src="/image-1@2x.png" />
          <div className={styles.div2}>를 통해 지금 바로 확인해 보세요</div>
        </div>
      </div>
      <div className={styles.image4Parent}>
        <img className={styles.image4Icon} alt="" src="/image-2@2x.png" />
        <img className={styles.image4Icon1} alt="" src="/image-3@2x.png" />
      </div>
    </div>
  );
};

export default Section1;

