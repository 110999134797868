import styles from "./Header.module.css";

const Header = () => {
  return (
    <div className={styles.header}>
      <img className={styles.logoIcon} alt="LOANGO" src="/mainlogo.png" />
    
    </div>
  );
};

export default Header;