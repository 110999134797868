import { useMemo } from "react";
import styles from "./CardContainer.module.css";

const CardContainer = ({
  stepNumber,
  rfpAutoFillText,
  rfpStepTitle,
  rfpImageUrl,
  rfpDescription,
  spanWidth,
}) => {
  const rFPStyle = useMemo(() => {
    return {
      width: spanWidth,
    };
  }, [spanWidth]);

  return (
    <div className={styles.rfpParent}>
      <div className={styles.rfp} style={rFPStyle}>
        <span className={styles.rfpTxtContainer}>
          <span>{stepNumber}</span>
          <span className={styles.rfp1}>{rfpAutoFillText}</span>
        </span>
      </div>
      <div className={styles.stepByStep}>{rfpStepTitle}</div>
      <img className={styles.image4Icon} alt="" src={rfpImageUrl} />
      <div className={styles.rfp10}>{rfpDescription}</div>
    </div>
  );
};

export default CardContainer;
