import GrowthContainer from "../components/GrowthContainer";
import styles from "./Ticker.module.css";
import { motion } from 'framer-motion';


const Ticker = () => {
  const items = [<GrowthContainer customerReview="공장 매입 자금이 부족해서 거래 은행에 문의하니 가능한 대출 금액이 너무 적었습니다. 포기할까 했는데 론고에서 좋은 한도를 제시해 주신 은행담당자분을 만나 해결할 수 있었네요. 감사합니다." companyDescription="(주) **전자 CEO / 경기도 안산시"/>,
<GrowthContainer customerReview="신규 고객을 찾고 상담하는데 많은 시간이 걸렸었는데 론고 앱을 이용해서 속도감 있는 영업을 전개할 수 있었어요 정말 이용해볼만한 좋은 서비스에요~" companyDescription="**은행 기업금융팀장 / 서울 영등포구 여의도동"/>,
<GrowthContainer customerReview="의뢰인들이 좋은 물건을 찾아도 결국은 성사여부는 자금조달 문제로 귀결 되더군요. 여기저기 찾아다니기 보단 한 번에 한도와 금리를 제안받을 수 있으니 고객들이 너무 좋아합니다."companyDescription="K**부동산중개법인 팀장/ 서울 종로구"/>]

const tickerWidth = 50; // Adjust as needed (e.g., 100% or another value)

  // Calculate the number of repetitions needed to fill the ticker width
  const repetitions = Math.ceil(tickerWidth / (100 / items.length));
  const repeatedItems = Array.from({ length: repetitions }, (_, index) => (
    <div key={index} className="ticker-item">
      {items.map((item, i) => (
        <span key={i}>{item}</span>
      ))}
    </div>
  ));
  return (
    <div className={styles.ticker}>
      <motion.div
  
        initial={{ x: '0' }}
        animate={{ x: `-${tickerWidth}%` }}
        transition={{ repeat: Infinity, duration: 15, ease: 'linear' }} // Adjust the duration and ease here
      >
        {repeatedItems}
      </motion.div>
    </div>
  );
}

  /*
  return (

<div className={styles.ticker}>
  
<motion.div
        initial={{ x: '100%' }}
        animate={{ x: `-${100 * repetitions}%` }}
        transition={{ repeat: Infinity, duration: 5, ease: "linear" }}
      >
        {repeatedItems.map((item, index) => (
          <span key={index} className="ticker-item">
            {item}
          </span>
        ))}
      </motion.div>
    </div>
  
   
    
  );
};

*/
export default Ticker;

