export const list = [
    {
      before: '+ 론고는 어떤 서비스인가요?',
      after: '-  론고는 어떤 서비스인가요?',
      detail :'론고는 중소기업이 대출을 받기 위한 과정 중 담당자 접촉, 상담, 금리와 한도 비교, 대출 승인 실패 등의 어려움을 해결하기 위한 플랫폼입니다. 중소기업의 대출 수요를 RFP로 표준화하여 정리• 발송하고 은행 등 제도권의 금융회사 기업금융전문가들이 빠르게 제안서를 작성할 수 있도록 돕습니다. 최종적으로는 중소기업이 선택한 제안서의 담당자와 매칭되는 서비스입니다.'

    },
    {
        before: '+ 론고는 신뢰할 수 있는 서비스인가요?',
        after: '-  론고는 신뢰할 수 있는 서비스인가요?',
        detail :
'네, 론고는 제안서 작성이 가능한 금융회사를 Whitelist로 관리하여 해당되는 금융회사 직원만이 제안서를 작성할 수 있도록 하고 있습니다. 구체적으로는 시중은행, 지방은행, 특수은행, 저축은행, 캐피탈사의 임직원을 대상으로 하며, 은행연합회 / 저축은행중앙회 / 여신금융업협회 등 제도권 회원사 위주로 별도 관리하고 있습니다. 휴대폰 본인인증 정보와 명함 인식 데이터를 상호 비교하고 제안서 매칭 시 해당 임직원의 명함이 고객님께 전송됩니다.'
    },
      {
        before: '+ RFP와 제안서는 어떤 의미인가요?',
        after: '-  RFP와 제안서는 어떤 의미인가요?',
        detail :
        'RFP는 Request of Proposal의 약자로 제안요청서를 의미합니다. 기업의 기본 정보와 필요한 대출에 내용을 제공하고 그에 맞는 제안서를 송부해달라는 의미의 문서입니다. 제안서는 법적구속력을 갖지 않는 의향서 수준의 문서입니다. 다만, 기업금융을 담당하는 금융회사 직원의 제안서인 만큼 한도와 금리를 가늠할 수 있고 제안 조건대로 대출 과정을 진행하겠는 의향이 담긴 것으로 그 의미가 있습니다.'
      },
      {
        before: '+ RFP를 전송하면 무조건 제안서를 받는건가요?',
        after: '-  RFP를 전송하면 무조건 제안서를 받는건가요?',
        detail :
'그렇지 않습니다. RFP를 발송한 것이 제안서의 수취여부를 담보하는 것은 아닙니다. 그렇지만, 다수의 기업금융 담당자에게 송부되고 열람이 가능하게 되어 제안서 접수 확률을 높여 자금조달의 실패 가능성을 줄이는데 도움이 됩니다. 또한 신용도가 부족할 경우, 전문 경력을 갖춘 담당자들이 보증기관 또는 정책성 상품 연결 등 다양한 제안을 할 수 있다는 점에서 RFP발송의 의미가 있습니다.'
    },
    {
        before: '+ 개인사업자도 서비스 이용이 가능한가요?',
        after: '-  개인사업자도 서비스 이용이 가능한가요?',
        detail :
'불가능합니다. 개인사업자의 경우 개인신용과 소득에 의존하여 대출한도가 산출되므로 금융회사의 비대면 서비스 또는 온라인비교서비스 등을 이용하시는 편이 유리하기에 론고에서는 개인사업자에 대한 서비스는 제공하지 않고 있습니다. 다만, 신용대출 외에 담보대출 등 관계 금융의 수요가 여전히 있으므로 향후 서비스 도입을 고려하고 있습니다.'    },
{
    before: '+ 수수료가 있나요?',
    after: '-  수수료가 있나요?',
    detail :
'론고의 역할은 데이터를 표준화하고 수요와 공급을 매칭시키는 것입니다. 따라서 RFP를 자동 작성하고 다수의 기업금융 담당자에게 발송하는 RFP발송수수료를 수취할 예정입니다. 다만, 현재 베타서비스 기간으로 수수료는 없는 상태입니다.'
},
{
    before: '+ 금융회사 직원의 서비스 이용절차는 어떻게 되나요?',
    after: '-  금융회사 직원의 서비스 이용절차는 어떻게 되나요?',
    detail :

' 금융회사 직원의 경우 ANDROID와 IOS 앱을 통해 서비스를 이용하실 수 있습니다. 이는 빠르게 기업금융 수요를 캐치하고 제안서를 제출하기 위해 모바일 서비스가 유연하기 때문입니다. 영업 ASSISTANT로 LOANGO 앱을 이용해 보시기 바랍니다.'},
{
    before: '+ 궁금한 점이 더 있어요',
    after: '-  궁금한 점이 더 있어요',
    detail :
'PC에서 이용하시게 되면 챗봇이 준비되어 있습니다. 또한 cs_loango@demandlink.co.kr로 문의주셔도 무방합니다.'
}  
]