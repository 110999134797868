import styles from "./Section5.module.css";
import Ticker from "./Ticker";

const Section5 = () => {
  return (

    <div className={styles.section5}>
      <b className={styles.b}>함께 성장하는 론고</b>
      <Ticker/>


    </div>
    
  );
};

export default Section5;
