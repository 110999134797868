import styles from "./Section4.module.css";
import { useCallback } from "react";

const Section4 = () => {
  const onAndriod = useCallback(()=>{
    window.location.href = "https://play.google.com/store/search?q=%EC%A0%95%EB%B6%8024&c=apps&hl=en-KR";
  },[]);
  const onIos = useCallback(()=> {
    window.location.href = "https://play.google.com/store/search?q=%EC%A0%95%EB%B6%8024&c=apps&hl=en-KR";
  },[]);

  return (
    <div className={styles.section4}>
      <b className={styles.app}>금융회사 전문가용 APP</b>
      <div className={styles.div}>
        <span className={styles.txt}>
          <span className={styles.span}>
              대출수요가 있는 고객을 찾고 계신가요?
          </span>
          <div className="stypes.span">
            금융사 직원용 앱을 통해 신규 영업기회를 빠르게 포착하여 제안서를 송부해 보세요
          </div>
        </span>
      </div>
      <div className={styles.mockup}>
        <img className={styles.appMockupIcon} alt="" src="/app-mockup@2x.png" />
        <img
          className={styles.appMockupIcon}
          alt=""
          src="/app-mockup2@2x.png"
        />
      </div>
      <div className={styles.store}>
        <button onClick = {onAndriod} className={styles.googleplay2} />
        <button onClick = {onIos} className={styles.appstore1} />
      </div>
    </div>
  );
};

export default Section4;
