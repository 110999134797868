import { useMemo } from "react";
import styles from "./GrowthContainer.module.css";

const GrowthContainer = ({
  customerReview,
  companyDescription,
  divBackgroundColor,
}) => {
  const mentionStyle = useMemo(() => {
    return {
      backgroundColor: divBackgroundColor,
    };
  }, [divBackgroundColor]);

  return (
    <div className={styles.mention} style={mentionStyle}>
      <img className={styles.icon} alt="" src="/quote.svg" />
      <div className={styles.div}>{customerReview}</div>
      <div className={styles.ceo}>{companyDescription}</div>
    </div>
  );
};

export default GrowthContainer;
